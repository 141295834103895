///
/// Future Imperfect by HTML5 UP
/// html5up.net | @ajlkn
/// Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
///

/* Main */

	#main {
		@include vendor('flex-grow', '1');
		-ms-flex: 1;
		width: 100%;
	}

	.text-danger {
		color: red;
	}